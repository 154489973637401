const DeliveryStatusLabels = [
	{
		key: 'type',
		value: 'Delivered Type',
	},
	{
		key: 'qty',
		value: 'Delivered Quantity',
		quantity: 'quantity',
	},
	{
		key: 'value',
		value: 'Delivered Value',
		unit: 'rupees',
	},
	{
		key: 'remarks',
		value: 'reason',
	},
];
export const OrderJourneyKeys = {
	OP: {
		key: 'OP',
		list: [
			{ key: 'brand', value: 'Brand' },
			{ key: 'order_qty', value: 'Order Quantity', quantity: 'quantity' },
			{ key: 'order_value', value: 'Order Value', unit: 'rupees' },
			{ key: 'salesman', value: 'Salesman' },
		],
		status: 'Order Placed',
		date: 'order_date',
	},
	OH: {
		key: 'OH',
		list: [],
		status: 'On Hold',
		date: 'date',
	},
	PU: {
		key: 'PU',
		list: [
			{ key: 'picked_qty', value: 'Picked Quantity', quantity: 'quantity' },
			{ key: 'picked_value', value: 'Picked Value', unit: 'rupees' },
		],
		status: 'Ready for Dispatch',
		date: 'picked_date',
	},
	BL: {
		key: 'BL',
		list: [{ key: 'remarks', value: 'Reason' }],
		status: 'Order Blocked',
		date: 'date',
	},
	UB: {
		key: 'UB',
		list: [],
		status: 'Order Unblocked',
		date: 'date',
	},
	DA: {
		key: 'DA',
		list: [],
		status: 'Delivery Attempted',
		date: 'delivery_attempted',
	},
	VA: {
		key: 'VA',
		list: [
			{ key: 'transport_manager', value: 'Transport Manager' },
			{ key: 'driver_name', value: 'Driver Name' },
			{ key: 'delivery_agent', value: 'Delivery Agent' },
			{ key: 'vehicle_no', value: 'Vehicle Number' },
		],
		status: 'Vehicle Allocated',
		date: 'allocation_date',
	},

	PD: {
		key: 'PD',
		list: DeliveryStatusLabels,
		status: 'Order Partially Delivered',
		date: 'date',
	},
	DL: {
		key: 'PD',
		list: DeliveryStatusLabels,
		status: 'Order Fully Delivered',
		date: 'date',
	},
	CA: {
		key: 'PD',
		list: [
			{
				key: 'remarks',
				value: 'Reason',
			},
		],
		status: 'Order Cancelled',
		date: 'date',
	},
	CO: {
		key: 'CO',
		list: [{ key: 'verified_by', value: 'Verified By' }],
		status: 'Return to FC Closure',
		date: 'rfc_closure_date',
	},
};
