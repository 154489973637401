/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, Table } from 'antd';
import { Flex, Button, Text, Box } from 'atoms';
import { getInvoicesList, triggerAutoVerification, getRejectedList } from 'store/invoiceSlice';
import { CashierSalesManStatsHeader } from 'components/pages/cashier';
import { useURL } from 'hooks';
import InvoiceCardViewCashier from './InvoiceCardViewCashier';
import { SearchBox } from 'components';

const BeginVerificationBox = styled(Box)`
	background-color: rgb(224, 243, 255);
	padding: 10px;
	margin: 10px 0px;
	text-align: center;
	font-weight: bold;
`;

const columnsData = [
	{
		title: 'Store Code',
		dataIndex: 'storeCode',
		key: 'storeCode',
		render: (storeCode) => <Text textTransform="uppercase">{storeCode}</Text>,
	},
	{
		title: 'Store',
		dataIndex: 'storeName',
		key: 'storeName',
		render: (storeName) => <Text textTransform="uppercase">{storeName}</Text>,
	},
	{
		title: 'Invoice No.',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		render: (invoice_no) => <Text textTransform="uppercase">{invoice_no}</Text>,
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Payment Mode',
		dataIndex: 'payment_type',
		key: 'payment_type',
	},
	{
		title: 'Reference',
		dataIndex: 'reference_number',
		key: 'reference_number',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		key: 'due_date',
	},
];
export function InvoicesVerifiedList() {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [isInValid, setIsInValid] = useState(false);
	const [allVerified, setAllVerified] = useState(false);
	const [dataSummary, setDataSummary] = useState({});
	const [headerList, setHeaderList] = useState(0);
	const [rejectedPaymentsList, setRejectedPaymentsList] = useState();
	const navigate = useNavigate();
	const urlData = useURL();
	const { role } = useSelector((state) => state.auth);
	const { dateTrigger } = useSelector((state) => state.invoice);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		/**
		 * On cashier login, show verification icon based on verified_by_segregator_status
		 */
		dispatch(getInvoicesList(urlData)).then((res) => {
			console.log(dataSummary);
			setDataSummary(res?.data_summary);
			setIsInValid(
				!res?.data?.every(
					(item) => item?.verified_by_segregator_status === 'VerifiedBySegregator'
				)
			);
			res?.data?.forEach((element) => {
				element.verification_checked =
					element?.verified_by_segregator_status === 'VerifiedBySegregator';
			});
			const isAllCompleted = res?.data?.every(
				(element) => element?.verified_by_cashier_status === 'VerifiedByCashier'
			);
			setInvoices(res?.data);
			setAllVerified(isAllCompleted);
		});
	}, [dispatch, urlData, role, dateTrigger]);

	function handleSearchChange(e) {
		setSearchText(e?.target?.value?.toLowerCase());
	}

	function handleAutoVerification() {
		const cid = invoices?.map((item) => item?.collection_invoice_id)?.toString();
		dispatch(
			triggerAutoVerification(
				cid,
				headerList.data_summary.allocation_date_time,
				headerList.data_summary.collected_person_id,
				headerList.data_summary.tab
			)
		).then(() => {
			startVerification();
		});
	}

	function onClickShowRejectedList() {
		dispatch(getRejectedList(headerList?.data_summary?.cid)).then((r) => {
			if (r.data.length !== 0) {
				setRejectedPaymentsList(r.data);
			} else {
				handleAutoVerification();
			}
		});
	}

	function onCancel() {
		setRejectedPaymentsList(null);
	}

	function startVerification() {
		navigate({
			pathname: `/cashier/payment-verify`,
			search: urlData?.queryString,
		});
	}

	return (
		<div>
			<CashierSalesManStatsHeader setHeaderData={setHeaderList} />
			<Flex
				justifyContent="space-between"
				style={{ alignItems: 'flex-end', marginBottom: '25px' }}
			>
				<SearchBox
					onSearch={handleSearchChange}
					placeholder="Search by Invoice Number"
					style={{ margin: 0 }}
				/>
				<Button
					onClick={onClickShowRejectedList}
					disabled={
						invoices?.length === 0 ||
						dataSummary?.cashier_action === 'DEFAULT' ||
						isInValid
					}
					style={{ height: '35px' }}
				>
					{allVerified ? 'View details' : 'Start Verification'}
				</Button>
			</Flex>
			{dataSummary?.cashier_action === 'DEFAULT' && (
				<BeginVerificationBox>
					To begin verification, ask the salesman to click the `Submit Collection` button.
				</BeginVerificationBox>
			)}

			<InvoiceCardViewCashier invoices={invoices} searchText={searchText} />

			<Modal
				centered
				visible={rejectedPaymentsList}
				title={<Text textAlign="center">List of rejected Payments</Text>}
				onOk={handleAutoVerification}
				onCancel={onCancel}
				okText="OK"
				width={1000}
				maskClosable={false}
				bodyStyle={{
					maxHeight: '500px',
					overflow: 'auto',
				}}
			>
				<Table
					rowKey={(record) => `${record?.key}`}
					pagination={false}
					dataSource={rejectedPaymentsList}
					columns={columnsData}
				/>
			</Modal>
		</div>
	);
}
