/* eslint-disable sonarjs/no-identical-functions */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { message as toaster } from 'antd';
import { omit } from 'lodash';
import { API } from 'api';

export const TODAY = moment(moment()).format('DD-MMM-YYYY');
const DynamicQrURL = process.env.REACT_APP_DYNAMIC_QR_API_BASE_URL;
const CashierRevampURLV1 = process.env.REACT_APP_CASHIER_API_URL_REVAMP_VERSION1;
const CashierRevampURLV2 = process.env.REACT_APP_CASHIER_API_URL_REVAMP_VERSION2;

const initialState = {
	collectionList: {
		count: 0,
		data: [],
		data_summary: {},
	},
	tab: '1',
	date: TODAY,
	dateTrigger: Math.random(), // used for to trigger to sales person list
	showRemarks: false,
	showRemarksRecord: {},
	remarkStatus: 'BULK',
	cashierPersonHeaderDetails: {},
	banks: [],
};

export const userSlice = createSlice({
	name: 'invoice', // name of the reducer
	initialState,
	reducers: {
		setCollectionList: (state, { payload }) => {
			const { count = 0, data = [], data_summary = {} } = payload;
			state.collectionList = {
				count,
				data: [...data],
				data_summary,
			};
		},
		setTab: (state, { payload = '1' }) => {
			state.tab = payload;
		},
		setDate: (state, { payload }) => {
			state.date = payload;
		},
		setDateTrigger: (state, { payload }) => {
			/**
			 * On salesperson list, if we are remarks we want to chagne the icon of that list,
			 * we used local state to handle the data,
			 * so used this variable to trigger api call in Dashboard.js page
			 */
			state.dateTrigger = payload;
		},
		setInvoiceList: (state, { payload }) => {
			const { count = 0, data = [], data_summary = {} } = payload;
			state.invoicelist = {
				count,
				data: [...data],
				data_summary,
			};
		},
		setShowRemarks: (state, { payload }) => {
			state.showRemarks = payload;
		},
		setShowRemarksRecord: (state, { payload }) => {
			state.showRemarksRecord = payload;
		},
		setRemarksStatus: (state, { payload }) => {
			state.remarkStatus = payload;
		},
		setCashierPersonHeaderDetails: (state, { payload }) => {
			state.cashierPersonHeaderDetails = payload;
		},
		setBanks: (state, { payload }) => {
			state.banks = payload;
		},
	},
});

export const getCollectionList =
	({ q = '' }) =>
	async (dispatch, getState) => {
		/**
		 * To get salesmen/Delivery Persons list
		 */
		const { date, tab } = getState().invoice;
		const { version } = getState().auth.auth;

		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}invoicecollectionlist`,
			queryParams: {
				search_info: q,
				search_date: date,
				tab,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setCollectionList(response));
			return response;
		});
	};

export const getInvoicesList = (data, rejectedInvoices) => async (dispatch, getState) => {
	/**
	 * 1. In Segregator - To get particular salesmen/Delivery Persons all invoices
	 * 2. In Cashier - To get particular salesmen/Delivery Persons all invoices
	 */

	const { tab } = getState().invoice;
	const { version } = getState().auth.auth;
	const { allocation_date_time, collected_person_id, return_status } = data;
	const queryParams = {
		search_info: '',
		tab,
		allocation_date_time,
		collected_person_id,
		return_status,
	};
	if (version === 'v2' && tab === '1') {
		queryParams.rejected_invoices = rejectedInvoices || false;
	}
	const CREDENTIALS = {
		url: `${
			version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
		}invoicelist`,
		queryParams,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export const saveSegregatorVerification = (data) => async (dispatch, getState) => {
	const { version } = getState().auth.auth;
	const { tab } = getState().invoice;
	/**
	 * SAVE - Call
	 * In Segregator login verify each invoices, clicking on tick and close icon
	 */
	const CREDENTIALS = {
		url: `${
			version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
		}invoiceVerifyBySeg`,
		data: {
			...data,
		},
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => {
		const message = response?.message.includes('partially') ? 'Partially Verified' : 'Verified';
		toaster.success(message);
		return response;
	});
};

export const getCashierHeader =
	(data = {}) =>
	async (dispatch, getState) => {
		const { tab } = getState().invoice;
		const { version } = getState().auth.auth;
		/**
		 * In Cashier app login, particular person header details
		 */
		const { allocation_date_time, collected_person_id, return_status } = data;
		const queryParams = {
			search_info: '',
			tab,
			allocation_date_time,
			collected_person_id,
			return_status,
		};
		if (version === 'v2' && tab === '1') {
			queryParams.rejected_invoices = false;
		}
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}invoicelist`,
			queryParams,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setCashierPersonHeaderDetails(response));
			return response;
		});
	};

export const getChequesUPIList =
	(tableParams, data = {}) =>
	async (dispatch, getState) => {
		const { tab } = getState().invoice;
		const { current, pageSize } = tableParams?.pagination || {};
		const { version } = getState().auth.auth;

		const {
			collected_person_id,
			allocation_date_time,
			verified_by_segregator_status,
			verified_by_cashier_status,
			return_status,
			payment_type,
		} = data;

		/**
		 * In Cashier app login, get salesperson cheques and UPI list based on payment_type(UPI/Cheque)
		 */

		const queryParams = {
			search_info: '',
			tab,
			offset: current ? (current - 1) * pageSize : undefined,
			limit: pageSize,
			payment_type,
			collected_person_id,
			allocation_date_time,
			verified_by_segregator_status,
			verified_by_cashier_status,
			return_status,
		};
		if (version === 'v2' && tab === '1') {
			queryParams.rejected_invoices = false;
		}
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}cashierVerificationList`,
			queryParams,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

export const saveChequesOrUPIList =
	(data = {}) =>
	async (dispatch, getState) => {
		/**
		 * SAVE - Call
		 * In Cashier app login, save(verify) cheques, upi list
		 */
		const { tab } = getState().invoice;
		const { version } = getState().auth.auth;
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}chequeUpiVerifyByCashier`,
			data: {
				tab,
				...data,
			},
			method: 'post',
		};

		return API.common(CREDENTIALS).then((response) => {
			toaster.success(response?.msg);
			return response;
		});
	};

export const rejectChequesOrUPIList =
	(data = {}) =>
	async (dispatch, getState) => {
		const { tab } = getState().invoice;
		const { version } = getState().auth.auth;
		// const {
		// 	cid = '',
		// 	collected_person_id = '',
		// 	allocation_date_time = '',
		// } = cashierPersonHeaderDetails?.data || {};

		/**
		 * To get the what are the corresponding invoices going to reject because of this current rejection
		 */
		const CREDENTIALS = {
			// url: `${CashierRevampURLV1}rejectingInvoicePaymentsChequeUpi`,
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}rejectingInvoicePaymentsChequeUpi`,
			data: {
				tab,
				...data,
			},
			method: 'post',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

export const upiBulkVerify = (selected, userInfo) => async (dispatch, getState) => {
	const { version } = getState().auth.auth;
	const { tab } = getState().invoice;
	const UpiDetails = {
		user_id: userInfo.id,
		data: selected.map((i) => ({
			utr: i.referenceNumber,
			amount: i.amount,
		})),
	};

	const query = {
		url: `${version === 'v1' || tab === '2' ? DynamicQrURL : CashierRevampURLV2}upi/check-upi`,
		method: 'post',
		data: UpiDetails,
	};
	return API.common(query).then((response) => response);
};

export const updateUpiStatus =
	(selected, userInfo, cashierDetails = {}) =>
	async (dispatch, getState) => {
		const { version } = getState().auth.auth;
		const { tab } = getState().invoice;

		const UpiDetails = {
			user_id: userInfo.id,
			data: selected.map((i) => ({
				utr: i.referenceNumber,
				amount: i.amount,
				invoice_id: i.invoice_ids.split(','),
			})),
		};
		if (version === 'v2') {
			const { allocation_date_time, collected_person_id } = cashierDetails;
			UpiDetails.allocation_date_time = allocation_date_time;
			UpiDetails.collected_person_id = collected_person_id;
		}

		const query = {
			url: `${
				version === 'v1' || tab === '2' ? DynamicQrURL : CashierRevampURLV2
			}upi/update-upi-status`,
			method: 'put',
			data: UpiDetails,
		};

		return API.common(query).then((response) => response);
	};

export const getRejectedList =
	(cid = '') =>
	async (dispatch, getState) => {
		const { version } = getState().auth.auth;
		const { tab } = getState().invoice;
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}paymentRejectionlist`,
			queryParams: {
				cid,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};
export const getCashierStateTransition = (data) => async () => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV2}collectionModeState?collected_person_id=${data?.collected_person_id}&allocation_date_time=${data?.allocation_date_time}&tab=1`,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export const rejectCash =
	(data = {}) =>
	async (dispatch, getState) => {
		const { cashierPersonHeaderDetails, tab } = getState().invoice;
		const {
			cid = '',
			collected_person_id = '',
			allocation_date_time = '',
		} = cashierPersonHeaderDetails?.data || {};

		/**
		 * To get the what are the corresponding invoices going to reject because of this cash rejection
		 */
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}rejectingInvoicePaymentsCash`,
			data: {
				cid,
				tab,
				collected_person_id,
				allocation_date_time,
				...omit(data, ['queryString', 'brand_id']),
			},
			method: 'post',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

export const getDenominations =
	(data = {}) =>
	async (dispatch, getState) => {
		const { tab } = getState().invoice;
		const { version } = getState().auth.auth;
		/**
		 * In Cashier app login, get salesperson cheques list
		 */
		const { collected_person_id, allocation_date_time } = data;
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}denominationDetails`,
			queryParams: {
				tab,
				collected_person_id,
				allocation_date_time,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

export const saveCashSettlement =
	(data = {}) =>
	async (dispatch, getState) => {
		const { tab, cashierPersonHeaderDetails } = getState().invoice;
		const { version } = getState().auth.auth;
		/**
		 * SAVE - Call
		 * In Cashier app login, save(verify) collected cash list
		 */

		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}cashVerifyByCashier`,
			data: {
				cid: cashierPersonHeaderDetails?.data?.cid,
				tab,
				...omit(data, ['queryString', 'brand_id']),
			},
			method: 'post',
		};

		return API.common(CREDENTIALS).then(
			(response) =>
				// toaster.success(response?.msg);
				response
		);
	};

export const triggerAutoVerification =
	(cid = '', allocation_date_time = '', collected_person_id = '') =>
	async (dispatch, getState) => {
		const { version } = getState().auth.auth;
		const { tab } = getState().invoice;
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}invoiceAutoVerification`,
			data: {
				cid,
				allocation_date_time,
				collected_person_id,
				tab,
			},
			method: 'post',
		};

		return API.common(CREDENTIALS).then((response) => {
			if (response?.msg) {
				toaster.success(response?.msg);
			}
			return response;
		});
	};

/**
 *
 * Remarks api starts here
 */
export const getRemarksList = (data) => async (dispatch, getState) => {
	const { tab } = getState().invoice;
	const {
		remark_status,
		brand_id,
		allocation_date_time,
		collected_person_id,
		collection_invoice_id,
		payment_ids,
		unique_key_code,
	} = data;
	const { version } = getState().auth.auth;
	const CREDENTIALS = {
		url: `${
			version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
		}invoicecollectionremarklist`,
		queryParams: {
			search_info: '',
			tab,
			remark_status,
			collection_invoice_id,
			allocation_date_time,
			brand_id,
			unique_key_code,
			payment_ids,
			collected_person_id,
		},
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export const manualNeftVerification =
	(allocation_date_time, collected_person_id, cid = '', pid = '') =>
	async (dispatch, getState) => {
		const { version } = getState().auth.auth;
		const { tab } = getState().invoice;
		const v1Data = {
			cid,
			pid,
		};
		const v2Data = {
			allocation_date_time,
			collected_person_id,
			cid,
			pid,
			tab,
		};

		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}neftVerifyByCashier`,
			data: version === 'v1' || tab === '2' ? v1Data : v2Data,
			method: 'post',
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

// export const saveRemarks = (data) => async (dispatch, getState) => {
// 	const { tab } = getState().invoice;
// 	/**
// 	 * SAVE - Call
// 	 * Save Remarks
// 	 */

// 	const data_main = data;
// 	data_main.tab = tab;
// 	const { version } = getState().auth.auth;
// 	const CREDENTIALS = {
// 		url: `${
// 			version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
// 		}invoicecollectionremarkadd`,
// 		data: data_main,
// 		method: 'post',
// 	};

// 	return API.common(CREDENTIALS).then((response) => {
// 		// dispatch(setDateTrigger(Math.random())); // To trigger users list (hack only)
// 		toaster.success('Remark added successfully');
// 		return response;
// 	});
// };

export const saveRemarks = (data) => async (dispatch, getState) => {
	const { tab } = getState().invoice;
	const { version } = getState().auth.auth;

	// Use object spreading to create a new object instead of mutating the original data
	const data_main = {
		...data,
		tab,
	};

	// Construct the URL outside the CREDENTIALS object for clarity
	const baseUrl = version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2;
	const Mainurl = `${baseUrl}invoicecollectionremarkadd`;

	const CREDENTIALS = {
		url: Mainurl,
		data: data_main,
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success('Remark added successfully');
		return response;
	});
};

export const callStartreverification = (salesmanData) => async () => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV2}segstartverification`,
		method: 'post',
		data: {
			salesman_id: salesmanData?.collected_person_id,
			allocation_date: salesmanData?.allocation_date_time,
			tab: salesmanData?.tab,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

/**
 *
 * Remarks api ends here
 */

// bank settlement api starts here

export const getCashChequeUnsettleAmt = () => async () => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV1}getTotalCashChequeUnsettledAmount`,
		method: 'get',
	};
	console.log('getTotalCashChequeUnsettledAmount');
	return API.common(CREDENTIALS).then((response) => {
		console.log(response);
		return response.data;
	});
};

export const CashSettlementInsert = (data) => async () => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV1}CashSettlementInsert`,
		data,
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const ChequeSettlementInsert = (data) => async () => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV1}ChequeSettlementInsert`,
		data,
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getPrintCheques =
	(
		ids = 0,
		// adhocIds = 0,
		bank_id = null
	) =>
	async () => {
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}downloadchequepdf?id=${ids}&ripplr_bank_id=${bank_id}`,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			console.log(response);
			window.open(response?.data);
		});
	};

export const getUnSettleChequelist =
	({ q = '' }) =>
	async () => {
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}chequelistWithNewUnsettledwithFutureDueDate`,
			queryParams: {
				search_info: q,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getAlChequeListForSettlement =
	({ q = '', offset = 0, limit = 2000 }) =>
	async () => {
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}chequelistForSettlement?search_info=${q}&limit=${limit}&offset=${offset}`,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			// eslint-disable-next-line sonarjs/prefer-object-literal
			const data_d = {};
			data_d.rows = response?.data?.chequelistSettlement?.map((item) => ({
				item,
				amount: item?.amount,
				bankName: item?.bankName,
				dueDate: item?.dueDate,
				referenceNumber: item?.referenceNumber,
				payment_ids: item?.payment_ids,
				invoice_nos: item?.invoice_nos,
				id: Number(item?.payment_ids[0]),
			}));
			data_d.count = response.count;
			return data_d;
		});
	};

export const getChequeListForSettlement =
	({ q = '', offset = 0, limit = 1000 }) =>
	async () => {
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}chequelistForSettlement?search_info=${q}&limit=${limit}&offset=${offset}`,

			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			toaster.success('Loading Cheque list');
			console.log(response);
			// eslint-disable-next-line sonarjs/prefer-object-literal
			const data_d = {};
			data_d.rows = response?.data?.chequelistSettlement?.map((item) => ({
				item,
				amount: item?.amount,
				bankName: item?.bankName,
				dueDate: item?.dueDate,
				referenceNumber: item?.referenceNumber,
				payment_ids: item?.payment_ids,
				invoice_nos: item?.invoice_nos,
				id: Number(item?.payment_ids[0]),
				payementID: Number(item?.payment_ids[0]),
				selectedItem: false,
			}));
			data_d.count = response.count;

			console.log(data_d);
			return data_d;
		});
	};

export const getCashChequeSettlementList =
	({ tab, from_date = '', to_date = '', search_info = '' }) =>
	async () => {
		const CREDENTIALS = {
			url: `${CashierRevampURLV1}${
				tab === 'cashSettlementlist' ? 'cashSettlementlist' : 'chequeSettlementlist'
			}`,
			queryParams: {
				from_date,
				to_date,
				search_info,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

// bank settlement api ends here

export const getBanksList = () => async (dispatch) => {
	const CREDENTIALS = {
		url: `${CashierRevampURLV1}ripplrBankAccountlist`,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setBanks(response.data));
		return response;
	});
};

export const {
	setCollectionList,
	setTab,
	setDate,
	setDateTrigger,
	setInvoiceList,
	setShowRemarks,
	setShowRemarksRecord,
	setCashChequeUnsettleAmt,
	setRemarksStatus,
	setCashierPersonHeaderDetails,
	setBanks,
} = userSlice.actions;

export default userSlice.reducer;
